import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  ArenasFilterInfo,
  ArenasObject,
} from '@/store/modules/operations/ArenasModule';

export interface NotificationsObject {
  id: string;
  userId: string;
  parentId: string;
  isSeen: boolean;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
  type: string;
  entityId: string;
}

export interface NotificationListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  notifications: Array<NotificationsObject>;
  errors: unknown;
}

@Module
export default class NotificationModule
  extends VuexModule
  implements NotificationListInfo
{
  notifications = [] as Array<NotificationsObject>;
  notificationsParent = [] as Array<NotificationsObject>;
  parentPage = 1;
  page = 1;
  take = 10;
  itemCount = 0;
  itemParentCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get notification list
   * @returns Array<AnnouncementObject>
   */
  get notificationList(): Array<NotificationsObject> {
    return this.notifications;
  }
  get notificationCount(): number {
    return this.itemCount;
  }
  get notificationPageCount(): number {
    return this.itemParentCount;
  }
  get notificationPrent(): Array<NotificationsObject> {
    return this.notificationsParent;
  }

  @Mutation
  [Mutations.SET_NOTIFICATIONS](data) {
    this.notifications = [...this.notifications, ...data.data];
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_NOTIFICATIONS_PARENT](data) {
    this.notificationsParent = data.data;
    this.parentPage = data.meta.page;
    this.take = data.meta.take;
    this.itemParentCount = data.meta.itemParentCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.RESET_NOTIFICATION_LIST]() {
    this.notifications = [] as Array<NotificationsObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }
  @Mutation
  [Mutations.RESET_NOTIFICATION_PARENT_LIST]() {
    this.notificationsParent = [] as Array<NotificationsObject>;
    this.parentPage = 1;
    this.take = 10;
    this.itemParentCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_NOTIFICATION_STORE]() {
    this.context.commit(Mutations.RESET_NOTIFICATION_LIST);
  }
  @Action
  [Actions.RESET_NOTIFICATION_PARENT_STORE]() {
    this.context.commit(Mutations.RESET_NOTIFICATION_PARENT_LIST);
  }

  @Mutation
  [Mutations.SET_NOTIFICATIONS_CURRENT_PAGE](page) {
    this.page = page;
  }
  @Mutation
  [Mutations.SET_NOTIFICATIONS_PARENT_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Action
  [Actions.UPDATE_NOTIFICATION]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/notification', id, data).catch(
      ({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      }
    );
  }
  @Action
  [Actions.MARK_ALL_AS_READ]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/notification/mark-all-as-read', '', {}).catch(
      ({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      }
    );
  }

  @Action
  [Actions.UPDATE_NOTIFICATIONS_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_NOTIFICATIONS_CURRENT_PAGE, val);
  }
  @Action
  [Actions.UPDATE_NOTIFICATIONS_PARENT_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_NOTIFICATIONS_PARENT_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_NOTIFICATIONS]() {
    const params = {
      page: this.page,
      take: this.take,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/notification', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_NOTIFICATIONS, data);
        return data;
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
  @Action
  [Actions.GET_NOTIFICATIONS_PARENT_BY_ID](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`notification/parent`, id)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
  @Action
  [Actions.UPDATE_NOTIFICATION_PARENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('horse', id, data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_HORSES_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GET_NOTIFICATIONS_PARENT]() {
    const params = {
      page: this.parentPage,
      take: 10,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/notification/parent', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_NOTIFICATIONS_PARENT, data);
        return data;
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
  @Action
  [Actions.DELETE_NOTIFICATION_PARENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/notification/parent', id)
      .then(() => {
        this.context.commit(Mutations.RESET_NOTIFICATION_PARENT_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.CREATE_NOTIFICATIONS_PARENT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/notification/parent', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_NOTIFICATION_PARENT_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
