import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Actions, Mutations } from '@/store/enums/StoreEnums';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/sign-in',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/manage/members/profile',
        name: 'profile',
        component: () => import('@/views/manage/members/Profile.vue'),
      },
      {
        path: '/manage/members/roles-Listing/:id',
        name: 'roles-listing',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/roles/RolesListing.vue'
          ),
      },
      {
        path: '/manage/members/roles-creating/:id',
        name: 'roles-creating',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/roles/RolesCreating.vue'
          ),
      },
      {
        path: '/manage/members/roles-editing/:id',
        name: 'roles-editing',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/roles/RolesEditing.vue'
          ),
      },
      {
        path: '/manage/members/groups-listing',
        name: 'groups-listing',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/groups/GroupsListing.vue'
          ),
      },
      {
        path: '/manage/members/groups-creating',
        name: 'groups-creating',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/roles-groups/RolesGroupsCreating.vue'
          ),
      },
      {
        path: '/manage/members/groups-editing/:id',
        name: 'groups-editing',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/groups/GroupsEditing.vue'
          ),
      },
      {
        path: '/manage/members/admins-listing',
        name: 'admins-listing',
        component: () =>
          import('@/views/manage/members/admins/AdminsListing.vue'),
      },
      {
        path: '/manage/members/admins-creating',
        name: 'admins-creating',
        component: () =>
          import('@/views/manage/members/admins/AdminsCreating.vue'),
      },
      {
        path: '/manage/members/admins-editing/:id',
        name: 'admins-editing',
        component: () =>
          import('@/views/manage/members/admins/AdminsEditing.vue'),
      },
      {
        path: '/manage/setting/lookups/guardian-type/guardians-listing',
        name: 'guardians-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/guardian-type/GuardiansListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/guardian-type/guardian-creating',
        name: 'guardian-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/guardian-type/GuardianCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/guardian-type/guardian-editing/:id',
        name: 'guardian-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/guardian-type/GuardianEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/genders/genders-listing',
        name: 'genders-listing',
        component: () =>
          import('@/views/manage/settings/lookups/genders/GendersListing.vue'),
      },
      {
        path: '/manage/setting/lookups/genders/gender-creating',
        name: 'gender-creating',
        component: () =>
          import('@/views/manage/settings/lookups/genders/GenderCreating.vue'),
      },
      {
        path: '/manage/setting/lookups/genders/gender-editing/:id',
        name: 'gender-editing',
        component: () =>
          import('@/views/manage/settings/lookups/genders/GenderEditing.vue'),
      },
      {
        path: '/manage/setting/lookups/horse-gender/horse-gender-listing',
        name: 'horse-gender-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-gender/HorseGenderListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-gender/horse-gender-creating',
        name: 'horse-gender-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-gender/HorseGenderCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-gender/horse-gender-editing/:id',
        name: 'horse-gender-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-gender/HorseGenderEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-breed/horse-breed-listing',
        name: 'horse-breed-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-breed/HorseBreedListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-breed/horse-breed-creating',
        name: 'horse-breed-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-breed/HorseBreedCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-breed/horse-breed-editing/:id',
        name: 'horse-breed-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-breed/HorseBreedEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-color/horse-color-listing',
        name: 'horse-color-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-color/HorseColorListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-color/horse-color-creating',
        name: 'horse-color-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-color/HorseColorCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-color/horse-color-editing/:id',
        name: 'horse-color-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-color/HorseColorEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-category/horse-category-listing',
        name: 'horse-category-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-category/HorseCategoryListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-category/horse-category-creating',
        name: 'horse-category-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-category/HorseCategoryCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-category/horse-category-editing/:id',
        name: 'horse-category-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-category/HorseCategoryEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/leave-type/leave-type-listing',
        name: 'leave-type-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/leave-type/LeaveTypeListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/leave-type/leave-type-creating',
        name: 'leave-type-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/leave-type/LeaveTypeCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/leave-type/leave-type-editing/:id',
        name: 'leave-type-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/leave-type/LeaveTypeEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/cancel-reason/cancel-reason-listing',
        name: 'cancel-reason-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/cancel-reason/CancelReasonListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/cancel-reason/cancel-reason-creating',
        name: 'cancel-reason-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/cancel-reason/CancelReasonCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/cancel-reason/cancel-reason-editing/:id',
        name: 'cancel-reason-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/cancel-reason/CancelReasonEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/nationality/nationality-listing',
        name: 'nationality-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/nationality/NationalityListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/nationality/nationality-creating',
        name: 'nationality-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/nationality/NationalityCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/nationality/nationality-editing/:id',
        name: 'nationality-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/nationality/NationalityEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/passport-type/passports-listing',
        name: 'passports-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/passport-type/PassportListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/passport-type/passport-creating',
        name: 'passport-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/passport-type/PassportCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/passport-type/passport-editing/:id',
        name: 'passport-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/passport-type/PassportEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/payment-method/payment-methods-listing',
        name: 'payment-methods-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/payment-method/PaymentMethodListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/payment-method/payment-method-creating',
        name: 'payment-method-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/payment-method/PaymentMethodCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/payment-method/payment-method-editing/:id',
        name: 'payment-method-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/payment-method/PaymentMethodEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/document-type/documents-listing',
        name: 'documents-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/document-type/DocumentsListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/document-type/document-creating',
        name: 'document-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/document-type/DocumentCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/document-type/document-editing/:id',
        name: 'document-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/document-type/DocumentEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/treatment-type/treatments-listing',
        name: 'treatments-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/treatment-type/TreatmentsListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/treatment-type/treatment-creating',
        name: 'treatment-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/treatment-type/TreatmentCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/treatment-type/treatment-editing/:id',
        name: 'treatment-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/treatment-type/TreatmentEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/statuses/statuses-listing',
        name: 'statuses-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/statuses/StatusesListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/statuses/status-creating',
        name: 'status-creating',
        component: () =>
          import('@/views/manage/settings/lookups/statuses/StatusCreating.vue'),
      },
      {
        path: '/manage/setting/lookups/statuses/status-editing/:id',
        name: 'status-editing',
        component: () =>
          import('@/views/manage/settings/lookups/statuses/StatusEditing.vue'),
      },
      {
        path: '/manage/setting/lookups/course-status/course-status-listing',
        name: 'course-status-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/course-status/CourseStatusListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/course-status/course-status-creating',
        name: 'course-status-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/course-status/CourseStatusCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/course-status/course-status-editing/:id',
        name: 'course-status-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/course-status/CourseStatusEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-type/horse-type-listing',
        name: 'horse-type-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-type/HorseTypeListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-type/horse-type-creating',
        name: 'horse-type-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-type/HorseTypeCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/horse-type/horse-type-editing/:id',
        name: 'horse-type-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/horse-type/HorseTypeEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/service-type/services-listing',
        name: 'services-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/service-type/ServicesListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/service-type/service-creating',
        name: 'service-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/service-type/ServiceCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/service-type/service-editing/:id',
        name: 'service-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/service-type/ServiceEditing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/class-type/classes-listing',
        name: 'classes-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/class-type/ClassesListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/class-type/class-creating',
        name: 'class-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/class-type/ClassCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/class-type/class-editing/:id',
        name: 'class-editing',
        component: () =>
          import('@/views/manage/settings/lookups/class-type/ClassEditing.vue'),
      },
      {
        path: '/manage/setting/lookups/job-title/job-title-listing',
        name: 'job-title-listing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/job-title/JobTitleListing.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/job-title/job-title-creating',
        name: 'job-title-creating',
        component: () =>
          import(
            '@/views/manage/settings/lookups/job-title/JobTitleCreating.vue'
          ),
      },
      {
        path: '/manage/setting/lookups/job-title/job-title-editing/:id',
        name: 'job-title-editing',
        component: () =>
          import(
            '@/views/manage/settings/lookups/job-title/JobTitleEditing.vue'
          ),
      },
      {
        path: '/manage/setting/horse-location/horse-location-listing',
        name: 'horse-location-listing',
        component: () =>
          import(
            '@/views/manage/settings/horse-location/HorseLocationsListing.vue'
          ),
      },
      {
        path: '/manage/setting/horse-location/horse-location-creating',
        name: 'horse-location-creating',
        component: () =>
          import(
            '@/views/manage/settings/horse-location/HorseLocationCreating.vue'
          ),
      },
      {
        path: '/manage/setting/horse-location/horse-location-editing/:id',
        name: 'horse-location-editing',
        component: () =>
          import(
            '@/views/manage/settings/horse-location/HorseLocationEditing.vue'
          ),
      },
      {
        path: '/manage/setting/holiday-type/holidays-listing',
        name: 'holidays-listing',
        component: () =>
          import('@/views/manage/settings/holiday-type/HolidaysListing.vue'),
      },
      {
        path: '/manage/setting/holiday-type/holiday-creating',
        name: 'holiday-creating',
        component: () =>
          import('@/views/manage/settings/holiday-type/HolidayCreating.vue'),
      },
      {
        path: '/manage/setting/holiday-type/holiday-editing/:id',
        name: 'holiday-editing',
        component: () =>
          import('@/views/manage/settings/holiday-type/HolidayEditing.vue'),
      },
      {
        path: '/manage/setting/class-level/class-level-listing',
        name: 'class-level-listing',
        component: () =>
          import('@/views/manage/settings/class-level/ClassLevelListing.vue'),
      },
      {
        path: '/manage/setting/class-level/class-level-creating',
        name: 'class-level-creating',
        component: () =>
          import('@/views/manage/settings/class-level/ClassLevelCreating.vue'),
      },
      {
        path: '/manage/setting/class-level/class-level-editing/:id',
        name: 'class-level-editing',
        component: () =>
          import('@/views/manage/settings/class-level/ClassLevelEditing.vue'),
      },
      {
        path: '/manage/setting/class-category/class-category-listing',
        name: 'class-category-listing',
        component: () =>
          import(
            '@/views/manage/settings/class-category/ClassCategoryListing.vue'
          ),
      },
      {
        path: '/manage/setting/class-category/class-category-creating',
        name: 'class-category-creating',
        component: () =>
          import(
            '@/views/manage/settings/class-category/ClassCategoryCreating.vue'
          ),
      },
      {
        path: '/manage/setting/class-category/class-category-editing/:id',
        name: 'class-category-editing',
        component: () =>
          import(
            '@/views/manage/settings/class-category/ClassCategoryEditing.vue'
          ),
      },
      {
        path: '/manage/setting/customer-age/customer-age-listing',
        name: 'customer-age-listing',
        component: () =>
          import('@/views/manage/settings/customer-age/CustomerAgeListing.vue'),
      },
      {
        path: '/manage/setting/customer-age/customer-age-creating',
        name: 'customer-age-creating',
        component: () =>
          import(
            '@/views/manage/settings/customer-age/CustomerAgeCreating.vue'
          ),
      },
      {
        path: '/manage/setting/customer-age/customer-age-editing/:id',
        name: 'customer-age-editing',
        component: () =>
          import('@/views/manage/settings/customer-age/CustomerAgeEditing.vue'),
      },
      {
        path: '/manage/setting/about-us-listing',
        name: 'about-us-listing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageListing.vue'
          ),
      },
      {
        path: '/manage/setting/about-us-creating',
        name: 'about-us-creating',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageCreating.vue'
          ),
      },
      {
        path: '/manage/setting/about-us-editing/:id',
        name: 'about-us-editing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageEditing.vue'
          ),
      },
      {
        path: '/manage/setting/terms-of-use-listing',
        name: 'terms-of-use-listing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageListing.vue'
          ),
      },
      {
        path: '/manage/setting/terms-of-use-creating',
        name: 'terms-of-use-creating',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageCreating.vue'
          ),
      },
      {
        path: '/manage/setting/terms-of-use-editing/:id',
        name: 'terms-of-use-editing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageEditing.vue'
          ),
      },
      {
        path: '/manage/setting/privacy-listing',
        name: 'privacy-listing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageListing.vue'
          ),
      },
      {
        path: '/manage/setting/privacy-creating',
        name: 'privacy-creating',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageCreating.vue'
          ),
      },
      {
        path: '/manage/setting/privacy-editing/:id',
        name: 'privacy-editing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageEditing.vue'
          ),
      },
      {
        path: '/manage/setting/rules-regulations-listing',
        name: 'rules-regulations-listing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageListing.vue'
          ),
      },
      {
        path: '/manage/setting/rules-regulations-creating',
        name: 'rules-regulations-creating',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageCreating.vue'
          ),
      },
      {
        path: '/manage/setting/rules-regulations-editing/:id',
        name: 'rules-regulations-editing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageEditing.vue'
          ),
      },
      {
        path: '/manage/setting/complaints-listing',
        name: 'complaints-listing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageListing.vue'
          ),
      },
      {
        path: '/manage/setting/complaints-creating',
        name: 'complaints-creating',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageCreating.vue'
          ),
      },
      {
        path: '/manage/setting/complaints-editing/:id',
        name: 'complaints-editing',
        component: () =>
          import(
            '@/views/manage/settings/content-pages/ContentPageEditing.vue'
          ),
      },
      {
        path: '/manage/setting/announcement-listing',
        name: 'announcement-listing',
        component: () =>
          import(
            '@/views/manage/settings/announcement/AnnouncementListing.vue'
          ),
      },
      {
        path: '/manage/setting/announcement-creating',
        name: 'announcement-creating',
        component: () =>
          import(
            '@/views/manage/settings/announcement/AnnouncementCreating.vue'
          ),
      },
      {
        path: '/manage/setting/announcement-editing/:id',
        name: 'announcement-editing',
        component: () =>
          import(
            '@/views/manage/settings/announcement/AnnouncementEditing.vue'
          ),
      },
      {
        path: '/manage/setting/service-listing',
        name: 'service-listing',
        component: () =>
          import('@/views/manage/settings/services/ServicesListing.vue'),
      },
      {
        path: '/manage/setting/service-creating',
        name: 'service-creating',
        component: () =>
          import('@/views/manage/settings/services/ServicesCreating.vue'),
      },
      {
        path: '/manage/setting/service-editing/:id',
        name: 'service-editing',
        component: () =>
          import('@/views/manage/settings/services/ServicesEditing.vue'),
      },

      {
        path: '/operations/groomers/groomers-listing',
        name: 'operation-groomers-listing',
        component: () =>
          import('@/views/manage/operations/groomers/GroomerListing.vue'),
      },
      {
        path: '/operations/groomers/groomers-creating',
        name: 'operation-groomers-creating',
        component: () =>
          import('@/views/manage/operations/groomers/GroomerCreating.vue'),
      },
      {
        path: '/operations/groomers/groomers-editing/:id',
        name: 'operation-groomers-editing',
        component: () =>
          import('@/views/manage/operations/groomers/GroomerEditing.vue'),
      },
      {
        path: '/manage/operations/trainers/trainers-listing',
        name: 'trainers-listing',
        component: () =>
          import('@/views/manage/operations/trainers/TrainersListing.vue'),
      },
      {
        path: '/manage/operations/trainers/trainer-creating',
        name: 'trainer-creating',
        component: () =>
          import('@/views/manage/operations/trainers/TrainerCreating.vue'),
      },
      {
        path: '/manage/operations/trainers/trainer-editing/:id',
        name: 'trainer-editing',
        component: () =>
          import('@/views/manage/operations/trainers/TrainerEditing.vue'),
      },
      {
        path: '/manage/operations/coost-matrix',
        name: 'coost-matrix',
        component: () =>
          import('@/views/manage/operations/trainers/CoostMatrix.vue'),
      },
      {
        path: '/manage/operations/cost-matrix-v2',
        name: 'cost-matrix-v2',
        component: () =>
          import('@/views/manage/operations/trainers/costMatrixV2.vue'),
      },
      {
        path: '/manage/operations/arenas/arenas-listing',
        name: 'arenas-listing',
        component: () =>
          import('@/views/manage/operations/arenas/ArenasListing.vue'),
      },
      {
        path: '/manage/operations/arenas/arena-creating',
        name: 'arena-creating',
        component: () =>
          import('@/views/manage/operations/arenas/ArenaCreating.vue'),
      },
      {
        path: '/manage/operations/arenas/arena-editing/:id',
        name: 'arena-editing',
        component: () =>
          import('@/views/manage/operations/arenas/ArenaEditing.vue'),
      },
      {
        path: '/manage/operations/customers/customers-listing',
        name: 'customers-listing',
        component: () =>
          import('@/views/manage/operations/customers/CustomersListing.vue'),
      },
      {
        path: '/manage/operations/customers/customers-pending-account',
        name: 'customers-pending-account',
        component: () =>
          import(
            '@/views/manage/operations/pending-account/PendingAccountListing.vue'
          ),
      },
      {
        path: '/manage/operations/customers/customer-package-details/:id',
        name: 'customer-package-details',
        component: () =>
          import(
            '@/views/manage/operations/customers/CustomerPackageDetails.vue'
          ),
      },
      {
        path: '/manage/operations/customers/customer-creating',
        name: 'customer-creating',
        component: () =>
          import('@/views/manage/operations/customers/CustomerCreating.vue'),
      },
      {
        path: '/manage/operations/customers/customer-editing/:id',
        name: 'customer-editing',
        component: () =>
          import('@/views/manage/operations/customers/CustomerEditing.vue'),
      },
      {
        path: '/manage/operations/horses/horses-listing',
        name: 'horses-listing',
        component: () =>
          import('@/views/manage/operations/horses/HorsesListing.vue'),
      },
      {
        path: '/manage/operations/horses/treatment-overview/:id',
        name: 'treatment-overview',
        component: () =>
          import('@/views/manage/operations/horses/TreatmentOverview.vue'),
      },
      {
        path: '/manage/operations/horses/horses-creating',
        name: 'horses-creating',
        component: () =>
          import('@/views/manage/operations/horses/HorsesCreating.vue'),
      },
      {
        path: '/manage/operations/horses/horse-editing/:id',
        name: 'horse-editing',
        component: () =>
          import('@/views/manage/operations/horses/HorseEditing.vue'),
      },
      {
        path: '/manage/operations/lessons/lessons-listing',
        name: 'lessons-listing',
        component: () =>
          import('@/views/manage/operations/lessons/LessonsListing.vue'),
      },
      {
        path: '/manage/operations/lessons/lessons-creating',
        name: 'lessons-creating',
        component: () =>
          import('@/views/manage/operations/lessons/LessonsCreating.vue'),
      },
      {
        path: '/manage/operations/lessons/lessons-pending-listing',
        name: 'lessons-pending-listing',
        component: () =>
          import(
            '@/views/manage/operations/lessons-pending/LessonPendingListing.vue'
          ),
      },
      {
        path: '/manage/operations/staff/staff-listing',
        name: 'staff-listing',
        component: () =>
          import('@/views/manage/operations/staff/StaffListing.vue'),
      },
      {
        path: '/manage/operations/staff/staff-creating',
        name: 'staff-creating',
        component: () =>
          import('@/views/manage/operations/staff/StaffCreating.vue'),
      },
      {
        path: '/manage/operations/staff/staff-editing/:id',
        name: 'staff-editing',
        component: () =>
          import('@/views/manage/operations/staff/StaffEditing.vue'),
      },
      {
        path: '/manage/operations/shop/shop-listing',
        name: 'shop-listing',
        component: () =>
          import('@/views/manage/operations/shop/ShopListing.vue'),
      },
      {
        path: '/manage/operations/shop/shop-creating',
        name: 'shop-creating',
        component: () =>
          import('@/views/manage/operations/shop/ShopCreating.vue'),
      },
      {
        path: '/manage/operations/shop/shop-editing/:id',
        name: 'shop-editing',
        component: () =>
          import('@/views/manage/operations/shop/ShopEditing.vue'),
      },
      {
        path: '/manage/operations/category/category-listing',
        name: 'category-listing',
        component: () =>
          import('@/views/manage/operations/category/CategoryListing.vue'),
      },
      {
        path: '/manage/operations/category/category-creating',
        name: 'category-creating',
        component: () =>
          import('@/views/manage/operations/category/CategoryCreating.vue'),
      },
      {
        path: '/manage/operations/category/category-editing/:id',
        name: 'category-editing',
        component: () =>
          import('@/views/manage/operations/category/CategoryEditing.vue'),
      },
      {
        path: '/manage/operations/product/product-listing',
        name: 'product-listing',
        component: () =>
          import('@/views/manage/operations/products/ProductListing.vue'),
      },
      {
        path: '/manage/operations/product/product-creating',
        name: 'product-creating',
        component: () =>
          import('@/views/manage/operations/products/ProductCreating.vue'),
      },
      {
        path: '/manage/operations/product/product-editing/:id',
        name: 'product-editing',
        component: () =>
          import('@/views/manage/operations/products/ProductEditing.vue'),
      },
      {
        path: '/manage/operations/offer/offer-listing',
        name: 'offer-listing',
        component: () =>
          import('@/views/manage/operations/offer/OfferListing.vue'),
      },
      {
        path: '/manage/operations/offer/offer-creating',
        name: 'offer-creating',
        component: () =>
          import('@/views/manage/operations/offer/OfferCreating.vue'),
      },
      {
        path: '/manage/operations/offer/offer-editing/:id',
        name: 'offer-editing',
        component: () =>
          import('@/views/manage/operations/offer/OfferEditing.vue'),
      },
      {
        path: '/manage/operations/cancellation/cancellation-listing',
        name: 'cancellation-listing',
        component: () =>
          import(
            '@/views/manage/operations/cancellation/CancellationListing.vue'
          ),
      },
      {
        path: '/manage/operations/tickets/ticket-listing',
        name: 'ticket-listing',
        component: () =>
          import('@/views/manage/operations/tickets/TicketListing.vue'),
      },
      {
        path: '/manage/operations/notification/notification-listing',
        name: 'notifications-listing',
        component: () =>
          import(
            '@/views/manage/operations/notifications/notificationListing.vue'
          ),
      },
      {
        path: '/manage/operations/notification/notification-creating',
        name: 'notification-creating',
        component: () =>
          import(
            '@/views/manage/operations/notifications/notificationCreating.vue'
          ),
      },
      {
        path: '/manage/operations/notification/notification-editing/:id',
        name: 'notification-editing',
        component: () =>
          import(
            '@/views/manage/operations/notifications/notificationEditing.vue'
          ),
      },
      {
        path: '/manage/finance/invoice-listing',
        name: 'finance-invoice-listing',
        component: () =>
          import('@/views/manage/finance/invoice/InvoiceListing.vue'),
      },
      {
        path: '/manage/finance/invoice-creating',
        name: 'finance-invoice-creating',
        component: () =>
          import('@/views/manage/finance/invoice/InvoiceCreating.vue'),
      },
      {
        path: '/manage/finance/customer-credit-listing',
        name: 'finance-customer-credit-listing',
        component: () =>
          import(
            '@/views/manage/finance/customer-credit/CustomerCreditListing.vue'
          ),
      },
      {
        path: '/manage/finance/customer-credit-creating',
        name: 'finance-customer-credit-creating',
        component: () =>
          import(
            '@/views/manage/finance/customer-credit/CustomerCreditCreating.vue'
          ),
      },
      {
        path: '/manage/finance/customer-service-statment-listing',
        name: 'finance-customer-service-statment-listing',
        component: () =>
          import(
            '@/views/manage/finance/customer-service-statment/CustomerServiceStatmentListing.vue'
          ),
      },
      {
        path: '/manage/finance/customer-service-statment-creating',
        name: 'finance-customer-service-statment-creating',
        component: () =>
          import(
            '@/views/manage/finance/customer-service-statment/CustomerServiceStatmentCreating.vue'
          ),
      },
      {
        path: '/manage/reports/customer-attendance-report',
        name: 'customer-attendance-report',
        component: () =>
          import('@/views/manage/reports/CustomerAttendanceReport.vue'),
      },
      {
        path: '/manage/reports/horses-report',
        name: 'horses-report',
        component: () => import('@/views/manage/reports/HorsesReport.vue'),
      },
      {
        path: '/manage/reports/trainer-lesson-summary-report',
        name: 'trainer-lesson-summary-report',
        component: () =>
          import('@/views/manage/reports/TrainerLessonSummary.vue'),
      },
      {
        path: '/manage/reports/lessons-report',
        name: 'lessons-report',
        component: () => import('@/views/manage/reports/lessonReport.vue'),
      },
      {
        path: '/manage/reports/customer-lessons-report',
        name: 'customer-lessons-report',
        component: () =>
          import('@/views/manage/reports/customerLessonReport.vue'),
      },

      {
        path: '/manage/reports/all-customer-account-report',
        name: 'all-customer-account-report',
        component: () =>
          import('@/views/manage/reports/AllCustomerAccountReport.vue'),
      },
      {
        path: '/manage/reports/lessons-type-report',
        name: 'lessons-type-report',
        component: () =>
          import('@/views/manage/reports/lessonByTypeReport.vue'),
      },
      {
        path: '/manage/reports/horse-lessons-report',
        name: 'horse-lessons-report',
        component: () => import('@/views/manage/reports/HorseLessonReport.vue'),
      },
      {
        path: '/manage/reports/horse-lessons-count-report',
        name: 'horse-lessons-count-report',
        component: () =>
          import('@/views/manage/reports/HorseLessonCountReport.vue'),
      },
      {
        path: '/manage/reports/groomer-lessons-count-report',
        name: 'groomer-lessons-count-report',
        component: () =>
          import('@/views/manage/reports/GroomerLessonCountReport.vue'),
      },
      {
        path: '/manage/finance/customer-balance-statment-listing',
        name: 'finance-customer-balance-statment-listing',
        component: () =>
          import(
            '@/views/manage/finance/customer-balance-statment/CustomerBalanceStatmentListing.vue'
          ),
      },
      {
        path: '/manage/reports/customer-balance-report',
        name: 'customer-balance-report',
        component: () =>
          import('@/views/manage/reports/CustomerBalanceReport.vue'),
      },
      {
        path: '/manage/reports/customer-payment-report',
        name: 'customer-payment-report',
        component: () =>
          import('@/views/manage/reports/CustomerPaymentReport.vue'),
      },
      {
        path: '/manage/reports/customer-account-report',
        name: 'customer-account-report',
        component: () =>
          import('@/views/manage/reports/CustomerAccountReport.vue'),
      },
      {
        path: '/manage/reports/customer-statement-report',
        name: 'customer-statement-report',
        component: () =>
          import('@/views/manage/reports/CustomerStatemnetReport.vue'),
      },
      {
        path: '/manage/reports/horse-daily-lesson-night-report',
        name: 'horse-daily-lesson-night-report',
        component: () =>
          import('@/views/manage/reports/HorseDailyLessonNight.vue'),
      },
      {
        path: '/manage/reports/horse-daily-lesson-day-report',
        name: 'horse-daily-lesson-report',
        component: () => import('@/views/manage/reports/HorseDailyLesson.vue'),
      },
      {
        path: '/manage/reports/groom-daily-lesson-night-report',
        name: 'groom-daily-lesson-night-report',
        component: () =>
          import('@/views/manage/reports/GroomDailyLessonNight.vue'),
      },
      {
        path: '/manage/reports/groom-daily-lesson-day-report',
        name: 'groom-daily-lesson-report',
        component: () => import('@/views/manage/reports/GroomDailyLesson.vue'),
      },
      {
        path: '/manage/reports/arena-daily-lesson-night-report',
        name: 'arena-daily-lesson-night-report',
        component: () =>
          import('@/views/manage/reports/ArenaDailyLessonNight.vue'),
      },
      {
        path: '/manage/reports/arena-daily-lesson-day-report',
        name: 'arena-daily-lesson-report',
        component: () => import('@/views/manage/reports/ArenaDailyLesson.vue'),
      },
      {
        path: '/manage/reports/treatment-overview-report',
        name: 'treatment-overview-report',
        component: () =>
          import('@/views/manage/reports/TreatmentOverviewReport.vue'),
      },
      {
        path: '/manage/reports/horse-tag-report',
        name: 'horse-tag-report',
        component: () => import('@/views/manage/reports/HorseTagReport.vue'),
      },
      {
        path: '/manage/reports/all-horses',
        name: 'all-horses',
        component: () => import('@/views/manage/reports/AllHorses.vue'),
      },
      {
        path: '/manage/trainer/dashboard-trainer',
        name: 'dashboard-trainer',
        component: () =>
          import('@/views/manage/trainer-access/DashboardTrainer.vue'),
      },
      {
        path: '/manage/trainer/weekly-lessons',
        name: 'weekly-lessons',
        component: () =>
          import('@/views/manage/operations/lessons/weeklyLessonsListing.vue'),
      },
      {
        path: '/manage/trainer/attendence',
        name: 'attendence-list',
        component: () =>
          import('@/views/manage/operations/lessons/AttendenceList.vue'),
      },
      {
        path: '/manage/trainer/change-password',
        name: 'change-password-trainer',
        component: () =>
          import('@/views/manage/trainer-access/ChangePassword.vue'),
      },
    ],
  },

  {
    path: '/',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('@/views/authentication/SignIn.vue'),
        alias: ['sign-in', 'sign-in-trainer'],
      },
      {
        path: '/reset-password/:id',
        name: 'reset-password',
        component: () => import('@/views/authentication/ResetPassword.vue'),
      },
    ],
  },

  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/errors/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
export default router;
